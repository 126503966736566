import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import FeatureCard from "../components/FeatureCard"
import Navigation from "../components/Navigation"
import Pricing from "../components/Pricing"
import SEO from "../components/SEO"
import {
  MdFlipToBack,
  MdHistory,
  MdBugReport,
  MdLaunch,
  MdSettingsEthernet,
  MdSend,
  MdSync,
  MdFileDownload,
  MdSettings,
} from "react-icons/md"

import "../styles/site.scss"

const IndexPage = ({ data }) => (
  <div className="min-h-screen">
    <SEO title="Form processing and automation" />
    <div className="header">
      <Navigation />

      <div className="hero container mx-auto pt-4 lg:pt-10 pb-0 mb-10 lg:-mb-8 xl:-mb-24">
        <div className="flex flex-wrap justify-between z-10 relative">
          <div className="w-full lg:w-1/2 lg:order-last lg:pl-4">
            <div className="shadow-xl rounded bg-white">
              {/* prettier-ignore */}
              <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 820 32" className="browser-svg">
                <g fill="none" fill-rule="nonzero">
                    <rect width="100%" fill="#e8e8e8"></rect>
                    <circle stroke="#F04744" fill="#FF5F56" cx="20" cy="17" r="6"></circle>
                    <circle stroke="#EBA03F" fill="#FFBD2E" cx="40" cy="17" r="6"></circle>
                    <circle stroke="#13AB42" fill="#27C93F" cx="60" cy="17" r="6"></circle>
                </g>
              </svg>
              <Img
                className="rounded-b"
                fluid={data.file.childImageSharp.fluid}
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2 lg:pr-4 text-center lg:text-left pt-6 lg:pt-0">
            <div className="flex h-full items-center">
              <div>
                <h1 className="mb-4 text-orange-100">
                  Website form processing and automation
                </h1>
                <p className="mb-16 text-orange-200 opacity-50 text-xl">
                  Connect any form to the FormFlare processor for hassle free
                  data capture. No database or backend code required.
                </p>

                <div>
                  <a
                    className="btn btn-lg btn-primary block md:inline md:mr-6"
                    href="#pricing"
                  >
                    Try Form Flare
                  </a>
                  <div className="block md:inline-block text-orange-200 opacity-50 pt-4 md:pt-0">
                    Already using Form Flare?{" "}
                    <a
                      href="https://account.formflare.io"
                      className="text-orange-500 hover:text-orange-400 border-b border-dashed border-orange-600 pb-1"
                    >
                      Sign In
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* prettier-ignore */}
      <svg className="trapezoidal-horizintal fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon fill="#f7fafc" points="0,100 100,0 100,100" />
      </svg>
    </div>

    <div className="bg-gray-100">
      <section className="features container mx-auto pb-12 pt-8" id="features">
        <div className="lg:flex">
          <FeatureCard
            className="lg:pr-8"
            icon={<MdFlipToBack />}
            title="Unlimited Forms"
          >
            Create as many forms as you like, even on the free plan.
          </FeatureCard>
          <FeatureCard
            className="lg:px-8"
            icon={<MdHistory />}
            title="Submission Archive"
          >
            View form submissions at any point via our responsive web portal.
          </FeatureCard>
          <FeatureCard
            className="lg:pl-8"
            icon={<MdBugReport />}
            title="Spam Filtering"
          >
            Protect your forms from spam and abuse by using non intrusive spam
            protection.
          </FeatureCard>
        </div>
        <div className="lg:flex">
          <FeatureCard
            className="lg:pr-8"
            icon={<MdLaunch />}
            title="Custom Redirect"
          >
            Provide your own post-submission redirect URL for a fully branded
            end user experience.
          </FeatureCard>
          <FeatureCard
            className="lg:px-8"
            icon={<MdSettingsEthernet />}
            title="AJAX Ready"
          >
            Enable JSON repsonse to form submissions rather than using the
            in-built thank you page.
          </FeatureCard>
          <FeatureCard
            className="lg:pl-8"
            icon={<MdSend />}
            title="Form Actions"
          >
            Trigger actions on form submission to replicate data to third party
            services.
          </FeatureCard>
        </div>
        <div className="lg:flex">
          <FeatureCard className="lg:pr-8" icon={<MdSync />} title="Webhooks">
            Trigger applicaion callbacks using webhooks.
          </FeatureCard>
          <FeatureCard
            className="lg:px-8"
            icon={<MdSettings />}
            title="API Access"
          >
            Interact directly with your form submission data via our secure API.
          </FeatureCard>
          <FeatureCard
            className="lg:pl-8"
            icon={<MdFileDownload />}
            title="Export"
          >
            Download your form submission data in CSV, JSON or XSL format.
          </FeatureCard>
        </div>
      </section>
    </div>

    <div className="bg-gray-200">
      <Pricing />
    </div>
    <footer className="bg-mypurple text-white" role="contentinfo">
      <div className="text-center py-4">
        <span className="text-orange-200 opacity-50 text-sm">
          © 2017 - {new Date().getFullYear()}{" "}
          <a
            className="hover:underline"
            href="https://pandabridge.co.uk"
            alt="Panda Bridge Ltd."
          >
            Panda Bridge Ltd.
          </a>
        </span>
      </div>
    </footer>
  </div>
)

export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "forms.screenshot.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 750) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
