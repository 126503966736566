import React from "react"

const FeatureCard = props => (
  <div className={["w-full mb-8 lg:w-1/3", props.className].join(" ")}>
    <div className="text-center">
      <div className="flex items-center justify-center w-full">
        <div className="flex items-center justify-center bg-white shadow-md rounded-full h-20 w-20 mb-4">
          {React.cloneElement(props.icon, {
            className: "text-4xl mx-auto text-orange-600",
          })}
        </div>
      </div>
      <span className="block text-2xl font-semibold mb-2 text-gray-900">
        {props.title}
      </span>
      <p className="text-gray-700">{props.children}</p>
    </div>
  </div>
)

export default FeatureCard
