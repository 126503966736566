import React from "react"
import { MdCheckCircle } from "react-icons/md"
import Toggle from "react-toggle"
import { OutboundLink } from "gatsby-plugin-google-analytics";

export default class Pricing extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      isAnnual: false,
      standardAnnual: '---',
      standardMonthly: '---',
      advancedAnnual: '---',
      advancedMonthly: '---',
    }
  }
  
  componentDidMount = () => 
  {
    window.Paddle.Product.Prices(584422, function(prices) { this.setState({standardAnnual: prices.price.gross}); }.bind(this) );
    window.Paddle.Product.Prices(584501, function(prices) { this.setState({standardMonthly: prices.price.gross}); }.bind(this) );
    window.Paddle.Product.Prices(613254, function(prices) { this.setState({advancedAnnual: prices.price.gross}); }.bind(this) );
    window.Paddle.Product.Prices(613252, function(prices) { this.setState({advancedMonthly: prices.price.gross}); }.bind(this) );
  }

  handlePaymentDurationChange = () => {
    this.setState({ isAnnual: !this.state.isAnnual })
  }

  render() {
    let { isAnnual } = this.state
    let prices = {
      standard: 10,
      advanced: 25,
    }
    return (
      <section className="pricing container mx-auto py-24" id="pricing">
        <div className="w-full text-center">
          <label className="flex cursor-pointer max-w-fitcontent mx-auto" htmlFor="isAnnual">
            <span className="flex-grow text-right px-2 min-w-100">Monthly</span>
            <Toggle
              id="isAnnual"
              name="isAnnual"
              defaultChecked={isAnnual}
              icons={false}
              onChange={this.handlePaymentDurationChange}
            />
            <span className="flex-grow text-left px-2 min-w-100">Annually</span>
          </label>
        </div>
        <div className="lg:flex mb-4 mt-12">
          <div className="w-full mb-4 lg:w-1/3 lg:pr-6">
            <div className="h-full card bg-white shadow-md p-5 rounded flex flex-col">
              <div className="pricingHeader text-center border-b pb-4 mb-6">
                <span className="block text-2xl font-semibold">Trial</span>
                <span className="block text-sm text-gray-600">
                  30 day free trial
                </span>
                <span className="price block text-5xl  text-gray-800 font-semibold">
                  FREE
                </span>
                <div className="priceList flex flex-grow justify-center pt-2">
                  <ul>
                    <li>
                      <MdCheckCircle
                        className="inline-block text-orange-500 fill-current mr-2"
                        size={21}
                      />
                      30 Day Retention
                    </li>
                    <li>
                      <MdCheckCircle
                        className="inline-block text-orange-500 fill-current mr-2"
                        size={21}
                      />
                      50 Submissions
                    </li>
                  </ul>
                </div>
              </div>
              <div className="priceList flex flex-grow justify-center mb-8">
                <ul>
                  <li>
                    <MdCheckCircle
                      className="inline-block text-orange-500 fill-current mr-2"
                      size={21}
                    />
                    Unlimited Forms
                  </li>
                  <li>
                    <MdCheckCircle
                      className="inline-block text-orange-500 fill-current mr-2"
                      size={21}
                    />
                    Spam Filtering
                  </li>
                </ul>
              </div>
            <div className="btn btn-primary-pill btn-wide text-lg">
              Coming Soon
            </div>
            </div>
          </div>

          <div className="w-full mb-4 lg:w-1/3 lg:px-6">
            <div className="h-full card popularPrice bg-white p-5 rounded flex flex-col">
              <div className="pricingHeader text-center border-b pb-2 mb-6">
                <span className="block text-2xl font-semibold">Standard</span>
                <span className="block text-sm text-gray-600">
                  For individuals.
                </span>
                <span className="price block">
                  <span className={!isAnnual ? 'hidden' : ''}>
                    <span className="block leading-none mt-4 text-5xl text-gray-800 font-semibold">{this.state.standardAnnual}</span>
                    <span className="block text-sm text-gray-600">per year</span>
                  </span>
                  <span className={isAnnual ? 'hidden' : ''}>
                    <span className="block text-5xl leading-none mt-4 text-gray-800 font-semibold">{this.state.standardMonthly}</span>
                    <span className="block text-sm text-gray-600">per month</span>
                  </span>
                </span>
                <div className="priceList flex flex-grow justify-center pt-2">
                  <ul>
                    <li>
                      <MdCheckCircle
                        className="inline-block text-orange-500 fill-current mr-2"
                        size={21}
                      />
                      90 Day Retention
                    </li>
                    <li>
                      <MdCheckCircle
                        className="inline-block text-orange-500 fill-current mr-2"
                        size={21}
                      />
                      1000 Submissions
                    </li>
                  </ul>
                </div>
              </div>
              <div className="priceList flex flex-grow justify-center mb-8">
                <ul>
                  <li>Same as Trial, plus:</li>
                  <li>
                    <MdCheckCircle
                      className="inline-block text-orange-500 fill-current mr-2"
                      size={21}
                    />
                    Custom Redirect
                  </li>
                  <li>
                    <MdCheckCircle
                      className="inline-block text-orange-500 fill-current mr-2"
                      size={21}
                    />
                    AJAX Response
                  </li>
                  <li>
                    <MdCheckCircle
                      className="inline-block text-orange-500 fill-current mr-2"
                      size={21}
                    />
                    Form Actions
                  </li>
                </ul>
              </div>

              <OutboundLink href="https://account.formflare.io/signup/register" className="btn btn-primary btn-wide text-lg">
                Sign Up Now
              </OutboundLink>
              {/* <Link to={"/signup"} className="btn btn-primary btn-wide text-lg">
                Sign Up Now
              </Link> */}
            </div>
          </div>

          <div className="w-full mb-4 lg:w-1/3 lg:pl-6">
            <div className="h-full card bg-white shadow-md p-5 rounded flex flex-col">
              <div className="pricingHeader text-center border-b pb-4 mb-6">
                <span className="block text-2xl font-semibold">Advanced</span>
                <span className="block text-sm text-gray-600">
                  For teams and businesses.
                </span>
                <span className="price block text-5xl text-gray-800 font-semibold">
                  <span className={!isAnnual ? 'hidden' : ''}>
                    <span className="block leading-none mt-4 text-5xl text-gray-800 font-semibold">{this.state.advancedAnnual}</span>
                    <span className="block text-sm text-gray-600">per year</span>
                  </span>
                  <span className={isAnnual ? 'hidden' : ''}>
                    <span className="block text-5xl leading-none mt-4 text-gray-800 font-semibold">{this.state.advancedMonthly}</span>
                    <span className="block text-sm text-gray-600">per month</span>
                  </span>
                </span>
                <div className="priceList flex flex-grow justify-center pt-2">
                  <ul>
                    <li>
                      <MdCheckCircle
                        className="inline-block text-orange-500 fill-current mr-2"
                        size={21}
                      />
                      365 Day Retention
                    </li>
                    <li>
                      <MdCheckCircle
                        className="inline-block text-orange-500 fill-current mr-2"
                        size={21}
                      />
                      5000 Submissions
                    </li>
                  </ul>
                </div>
              </div>
              <div className="priceList flex flex-grow justify-center mb-8">
                <ul>
                  <li>Same as Standard, plus:</li>
                  <li>
                    <MdCheckCircle
                      className="inline-block text-orange-500 fill-current mr-2"
                      size={21}
                    />
                    Webhooks
                  </li>
                  <li>
                    <MdCheckCircle
                      className="inline-block text-orange-500 fill-current mr-2"
                      size={21}
                    />
                    API Access
                  </li>
                  <li>
                    <MdCheckCircle
                      className="inline-block text-orange-500 fill-current mr-2"
                      size={21}
                    />
                    Export
                  </li>
                </ul>
              </div>

              <div className="btn btn-primary-pill btn-wide text-lg">
                Coming Soon
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
